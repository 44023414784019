import NotificationTabs from "../../components/Notification/NotificationTabs";
import NotificationTitle from "../../components/Notification/NotificationTitle";
import NotificationListItem from "../../components/Notification/NotificationListItem";
import {Section} from "../../components/Section";
import {Table} from "../../components/Table";
import React, {useEffect, useState} from "react";
import {ReactComponent as BellIcon} from "../../assets/svg/icon-bell.svg";
import {getNotification, postNotiRead, postNotiReadAll} from "../../apis/notification";
import {NotiMessageDto} from "../../types/notification";
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";
import qs from "qs";
import {useUserState} from "../../context/UserContext";
import {nToast} from "../../plugins/reactToast";
import { isDatePassed } from '../../plugins/timeUtils';

const NotificationListPage = () => {
    const history = useHistory();
    const query = qs.parse(history.location.search, {ignoreQueryPrefix: true});
    const tabId = query.tab?.toString() ?? undefined;
    const page = Number(query.page ?? '0');
    const limit = Number(query.limit ?? '1000');
    const [notifications, setNotifications] = useState<NotiMessageDto[]>([]);
    const { isLoggedIn } = useUserState();
    const [resetKey, setResetKey] = useState<number>(0);

    const isPinnedItem = (notification: NotiMessageDto) => {
        return notification.pinnedStartDate && !isDatePassed(notification.pinnedStartDate);
    }

    const isContentViewed = (notification :NotiMessageDto)=> {
        return !notification.pinnedStartDate && Boolean(notification.readTs)
    };

    const onClickNotification = (notification: NotiMessageDto) => {

        const openNotification = () => {
            if (notification.linkUrl) {
                window.location.href = notification.linkUrl;
            } else {
                history.push(`/notification/${notification.id}`);
            }
        }

        if (isLoggedIn) {
            postNotiRead([notification.id]).then(() => {
                getNotificationList();
                openNotification();
            });
        } else {
            openNotification();
        }
    }

    const getNotificationList = () => {
        getNotification(page, limit, tabId)
            .then((res) => {
                setNotifications(res.data.payload.content);
            });
    }

    const readAll = () => {
        postNotiReadAll().then(() => {
            getNotificationList();
            setResetKey(resetKey + 1);
        });
    }

    useEffect(() => {
        getNotificationList();
        const rootElement = window.document.getElementById('root');
        rootElement?.setAttribute('class', 'bg-gray-100');

        return () => {
            rootElement?.removeAttribute('class');
        };
    }, [tabId]);

    useEffect(() => {
        if (page > 0) {
            getNotification(page, limit, tabId)
                .then((res) => {
                    const data = res.data.payload.content;
                    setNotifications([...notifications, ...data]);
                    if (data.length === 0) nToast('마지막 알림입니다.');
                });
        }
    }, [page]);

    return (
        <div>
            <NotificationTitle title={'알림센터'} showIcon={true}/>

            <NotificationTabs resetKey={resetKey}/>

            {notifications.length === 0 && <EmptyNotificationList/>}

            {notifications.length > 0 && (
                <>
                    <Section className="md:hidden">
                        <div className={'-mx-4 bg-gray-100'}>

                            {isLoggedIn && (
                                <div className={'pt-4 px-4 text-right text-gray-500 text-12'}>
                                    <button onClick={readAll}>전체읽음</button>
                                </div>
                            )}

                            <div className={'p-4 space-y-4'}>
                                {notifications.map((notification) => (
                                    <NotificationListItem key={notification.id}
                                                          notification={notification}
                                                          onclick={() => onClickNotification(notification)}
                                    />
                                ))}
                            </div>
                        </div>
                    </Section>

                    <Table className={'hidden md:table my-10 w-full max-w-5xl mx-auto'}>
                        <Table.Head>
                            <Table.Row className={'bg-gray-800'}>
                                <Table.ThLeft className={'text-gray-50 w-96'}>제목</Table.ThLeft>
                                <Table.Th className={'text-gray-50 text-center w-28'}>등록일</Table.Th>
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {notifications.map((notification, index) => (
                                <Table.Row key={notification.id}
                                           className={`${isContentViewed(notification) && 'opacity-30'} ${isPinnedItem(notification) ? 'bg-gray-200' : 'bg-white'} cursor-pointer`}
                                           onClick={() => onClickNotification(notification)}
                                >
                                    <Table.TdLeft
                                        className={'font-bold text-gray-900'}>{notification.title}</Table.TdLeft>
                                    <Table.Td className={`text-center border-l w-28`}>{dayjs(notification.displayStartDate).format('YYYY.MM.DD')}</Table.Td>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </>
            )}

        </div>
    )
}

const EmptyNotificationList = () => {
    return (
        <div className={'flex flex-col space-y-2 items-center py-12'}>
            <BellIcon/>
            <p className={'text-12'}>새로운 알림이 없습니다.</p>
        </div>
    )
}

export default NotificationListPage;
