import React, { useEffect, lazy, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AuthRoute } from '../components/AuthRoute';
import { TopNavBar } from '../components/TopNavBar';
import { Footer } from '../components/Footer';
import { BottomNavBar } from '../components/BottomNavBar';
import { historyState } from '../plugins/ridge';
import { useHistory } from 'react-router-dom';
import { some, startsWith } from 'es-toolkit/compat';
import { checkNetFunnelKey, getUserInfo } from '../apis/user';
import { NetFunnel } from '../index';
import NotificationListPage from './Notification/NotificationListPage';
import NotificationDetailPage from './Notification/NotificationDetailPage';
import NotificationSettingPage from './Notification/NotificationSettingPage';


const Verified = lazy(() => import('./verification'));
const PersonalVerificationPage = lazy(() => import('./PersonalVerification/PersonalVerificationPage'));
const HomePage = lazy(() => import('./HomePage'));
const Inspection = lazy(() => import('./inspection'));
const LoginPage = lazy(() => import('./LoginPage'));
const ProductListPage = lazy(() => import('./ProductListPage/ProductListPage'));
const NonMemberPage = lazy(() => import('./NonMemberPage'));
const SearchResultsPage = lazy(() => import('./SearchResultsPage/SearchResultsPage'));
const SignupSuccessPage = lazy(() => import('./SignupSuccessPage'));
const ProductPage = lazy(() => import('./ProductPage'));
const CartPage = lazy(() => import('./CartPage'));
const CartPaymentPage = lazy(() => import('./CartPaymentPage'));
const CartPaymentSuccessPage = lazy(() => import('./CartPaymentSuccessPage'));
const LookbookPage = lazy(() => import('./LookbookPage'));
const LookbookWomenPage = lazy(() => import('./LookbookWomenPage'));
const LookbookMenPage = lazy(() => import('./LookbookMenPage'));
const LookbookMenPreviewPage = lazy(() => import('./LookbookMenPreviewPage'));
const LookbookWoMenPreviewPage = lazy(() => import('./LookbookWoMenPreviewPage'));
const BrandPage = lazy(() => import('./BrandPage'));
const HistoryPage = lazy(() => import('./HistoryPage'));
const Certification = lazy(() => import('./certification'));
const CartPaymentProcessing = lazy(() => import('./CartPaymentProcessing'));
const CartRequestSuccessPage = lazy(() => import('./CartRequestSuccessPage'));
const NonMemberOrderDetail = lazy(() => import('./NonMemberOrderDetail'));
const CartPaymentProcessingRe = lazy(() => import('./CartPaymentProcessingRe'));
const UserCheck = lazy(() => import('./UserCheck'));
const FindId = lazy(() => import('./Find/FindId'));
const FindPassword = lazy(() => import('./Find/password'));
const Personalpolicy = lazy(() => import('./personalpolicy'));
const Terms = lazy(() => import('./terms'));
const CorporateResponsibilityPage = lazy(() => import('./CorporateResponsibility/CorporateResponsibilityPage'));
const MyPage = lazy(() => import('./MyPage/MyPage'));
const CustomerCarePage = lazy(() => import('./CustomerCare/CustomerCarePage'));
const ContactUsPage = lazy(() => import('./CustomerCare/ContactUsPage'));
const CustomPage = lazy(() => import('./CustomerCare/CustomPage'));
const FAQPage = lazy(() => import('./CustomerCare/FAQPage'));
const ShippingInfoPage = lazy(() => import('./CustomerCare/ShippingInfoPage'));
const WashingAndHandlingPage = lazy(() => import('./CustomerCare/WashingAndHandlingPage'));
const WorkWithUsPage = lazy(() => import('./WorkWithUsPage'));
const WorkWithUsDetailPage = lazy(() => import('./WorkWithUsDetailPage'));
const JournalPage = lazy(() => import('./JournalPage/JournalPage'));
const JournalDetailPage = lazy(() => import('./JournalDetailPage'));
const MyLikedPage = lazy(() => import('./MyLikedPage'));
const OutletsPage = lazy(() => import('./Outlet/OutletsPage'));
const MobileCategoryPage = lazy(() => import('./MobileCategoryPage'));
const MyPageOrdersCancel = lazy(() => import('./MyPage/MyPageOrdersCancel'));
const MyPageOrdersExchange = lazy(() => import('./MyPage/MyPageOrdersExchange'));
const MyPageOrdersEditSize = lazy(() => import('./MyPage/MyPageOrdersEditSize'));
const MyPageOrdersRefund = lazy(() => import('./MyPage/MyPageOrdersRefund'));
const LotteDetail = lazy(() => import('./Outlet/OutletDetailPage/LotteDetail'));
const SinsaDetail = lazy(() => import('./Outlet/OutletDetailPage/SinsaDetail'));
const TheHyndaiDetail = lazy(() => import('./Outlet/OutletDetailPage/TheHyunDaiDetail'));
const ApgujeongDetail = lazy(() => import('./Outlet/OutletDetailPage/ApgujeongDetail'));
const CentumDetail = lazy(() => import('./Outlet/OutletDetailPage/CentumDetail'));
const CheonanDetail = lazy(() => import('./Outlet/OutletDetailPage/CheonanDetail'));
const CoexDetail = lazy(() => import('./Outlet/OutletDetailPage/CoexDetail'));
const DaeguDetail = lazy(() => import('./Outlet/OutletDetailPage/DaeguDetail'));
const DongseongroDetail = lazy(() => import('./Outlet/OutletDetailPage/DongseongroDetail'));
const GwangjuDetail = lazy(() => import('./Outlet/OutletDetailPage/GwangjuDetail'));
const HongdaeDetail = lazy(() => import('./Outlet/OutletDetailPage/HongdaeDetail'));
const JejuDetail = lazy(() => import('./Outlet/OutletDetailPage/JejuDetail'));
const JeonjuDetail = lazy(() => import('./Outlet/OutletDetailPage/JeonjuDetail'));
const TimeDetail = lazy(() => import('./Outlet/OutletDetailPage/TimeDetail'));
const RefundDetail = lazy(() => import('./MyPage/RefundDetail'));

const CHECK_USER_PATHS = ['/mypage', '/my'];

const PRODUCT_LIST_PAGE_PATHS = ['/p/sale/:categoryName/:categoryIdString', '/p/sale/:categoryName/:categoryIdString/:subCategoryIdString', '/p/sale/:categoryName/:categoryIdString/:subCategoryIdString/:secondCategoryIdString', '/p/:categoryName/:categoryIdString', '/p/:categoryName/:categoryIdString/:subCategoryIdString', '/p/:categoryName/:categoryIdString/:subCategoryIdString/:secondCategoryIdString'];

export const Router = () => {
  const history = useHistory();

  const _checkUser = (pathname: string) => {
    if (some(CHECK_USER_PATHS, path => startsWith(pathname, path))) {
      getUserInfo().catch((e) =>
        e.response.status === 403 &&
        history.replace(`/login?location=${pathname}`),
      );
    }
  };

  const [isNetFunnelPassed, setIsNetFunnelPassed] = useState<boolean>(false);
  const getNetFunnelKey = () => {
    return new Promise(() => {
        const key = localStorage.getItem('ch-netfunnel-key');
        if (key !== null) {
          checkNetFunnelKey(key).then((res) => {
            if (res.data === true) {
              if (typeof NetFunnel?.NetFunnel_AliveNotice === 'function') {
                NetFunnel?.NetFunnel_AliveNotice();
              }
              setIsNetFunnelPassed(true);
            } else {
              setIsNetFunnelPassed(false);
              localStorage.removeItem('ch-netfunnel-key');
              getNetFunnelKey();
            }
          }).catch(() => {
            localStorage.removeItem('ch-netfunnel-key');
            getNetFunnelKey();
          });
        } else {
          if (typeof NetFunnel?.NetFunnel_Action === 'function') {
            NetFunnel?.NetFunnel_Action({
                action_id: process.env.REACT_APP_MODE === 'production' ? 'carhartt' : 'carhartt_dev',
              },
              {
                success: function(_: any, ret: any) {
                  if (typeof NetFunnel?.NetFunnel_AliveNotice === 'function') {
                    NetFunnel?.NetFunnel_AliveNotice();
                  }
                  localStorage.setItem('ch-netfunnel-key', ret.data.key);
                  setIsNetFunnelPassed(true);
                },
                error: function() {
                  localStorage.removeItem('ch-netfunnel-key');
                  getNetFunnelKey();
                },
              },
            )
            ;
          }
        }
      },
    );
  };
  const handleRouteChangeStart = () => {
    getNetFunnelKey();
  };
  const handleRouteChangeComplete = () => {
    localStorage.setItem('isNotCompleted', 'false');
  };

  const handleNetFunnelComplete = () => {
    if (typeof NetFunnel?.NetFunnel_Complete === 'function') {
      const isNotCompleted = localStorage.getItem('isNotCompleted');
      if (isNotCompleted === 'true') {
        localStorage.setItem('isNotCompleted', 'false');
      } else {
        localStorage.removeItem('ch-netfunnel-key');
        localStorage.removeItem('isNotCompleted');
        NetFunnel?.NetFunnel_Complete();
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem('ch-netfunnel-key') !== null) {
      const timer = setTimeout(() => {
        // 3분 후 쿠키 삭제
        localStorage.removeItem('ch-netfunnel-key');
        localStorage.removeItem('isNotCompleted');
        if (typeof NetFunnel?.NetFunnel_Complete === 'function') {
          NetFunnel?.NetFunnel_Complete();
        }
      }, 3 * 60 * 1000); // 3분 = 3 * 60 * 1000 밀리초
      // 경로 변경 시 타이머 리셋
      return () => {
        clearTimeout(timer);
      };
    }
  }, [history, isNetFunnelPassed]);


  useEffect(() => {
    const unlisten = history.listen(() => {
      handleRouteChangeStart();
      Promise.resolve().then(() => handleRouteChangeComplete());
    });
    window.addEventListener('beforeunload', handleNetFunnelComplete);
    getNetFunnelKey();
    return () => {
      unlisten();
      window.removeEventListener('beforeunload', handleNetFunnelComplete);
    };
  }, []);


  useEffect(() => {
    historyState.set(history);
    _checkUser(history.location.pathname);
    history.listen((location, action) => {
      _checkUser(location.pathname);
    });
  }, []);

  return (
    <>
      {isNetFunnelPassed ? (
        <div className="flex flex-col min-h-screen">
          <TopNavBar />
          <main className="flex-1 relative px-4 overflow-hidden">
            <Switch>
              <Route path="/journal/:id" exact component={JournalDetailPage} />
              <Route path="/journal" exact component={JournalPage} />
              <Route path="/sinsadetail" exact component={SinsaDetail} />
              <Route path="/thehyundaidetail" exact component={TheHyndaiDetail} />
              <Route path="/apgujeongdetail" exact component={ApgujeongDetail} />
              <Route path="/centumdetail" exact component={CentumDetail} />
              <Route path="/cheonandetail" exact component={CheonanDetail} />
              <Route path="/coexdetail" exact component={CoexDetail} />
              <Route path="/daegudetail" exact component={DaeguDetail} />
              <Route path="/dongseongrodetail" exact component={DongseongroDetail} />
              <Route path="/gwangjudetail" exact component={GwangjuDetail} />
              <Route path="/hongdaedetail" exact component={HongdaeDetail} />
              <Route path="/jejudetail" exact component={JejuDetail} />
              <Route path="/jeonjudetail" exact component={JeonjuDetail} />
              <Route path="/timedetail" exact component={TimeDetail} />
              <Route path="/lottedetail" exact component={LotteDetail} />
              <Route path="/work-with-us/:id" exact component={WorkWithUsDetailPage} />
              <Route path="/work-with-us" exact component={WorkWithUsPage} />
              <Route path="/cc" exact component={CustomerCarePage} />
              <Route path="/cc/contact-us" exact component={ContactUsPage} />
              <Route path="/cc/custom/:id" exact component={CustomPage} />
              <Route path="/cc/faq" exact component={FAQPage} />
              <Route path="/cc/shipping-info" exact component={ShippingInfoPage} />
              <Route path="/cc/cautions-for-washing-and-handling" exact component={WashingAndHandlingPage} />
              <Route path="/verification" exact component={Verified} />
              <Route path="/history" exact component={HistoryPage} />
              <Route path="/certification" exact component={Certification} />
              <Route path="/personalpolicy" exact component={Personalpolicy} />
              <Route path="/inspection" exact component={Inspection} />
              <Route path="/terms" exact component={Terms} />
              <Route path="/one-brand" exact component={BrandPage} />
              <Route path="/store" exact component={OutletsPage} />
              <Route path="/lookbook/2025ss-men" exact component={LookbookMenPage} />
              <Route path="/lookbook/2025ss-women" exact component={LookbookWomenPage} />
              <Route path="/lookbook/2025ss-men-preview" exact component={LookbookMenPreviewPage} />
              <Route path="/lookbook/2025ss-women-preview" exact component={LookbookWoMenPreviewPage} />
              <Route path="/lookbook" exact component={LookbookPage} />
              <Route path="/results/:keyword" exact component={SearchResultsPage} />

              <Route
                path="/non-member/:orderNumber/edit-size" exact
                component={MyPageOrdersEditSize}
              />
              <Route
                path="/non-member/:orderNumber/exchange" exact
                component={MyPageOrdersExchange}
              />
              <Route
                path="/non-member/:orderNumber/refund" exact
                component={MyPageOrdersRefund}
              />
              <Route
                path="/non-member/:orderNumber/cancel" exact
                component={MyPageOrdersCancel}
              />
              <Route
                path="/non-member/refunds/:orderNumber" exact
                component={RefundDetail}
              />
              <Route
                path="/non-member/:orderNumber" exact
                component={NonMemberOrderDetail}
              />
              <AuthRoute path="/non-member" component={NonMemberPage} guestOnly />

              <Route path={'/check/:to'} exact component={UserCheck} />

              <Route
                path="/cart/payment/success/:mobile" exact
                component={CartPaymentSuccessPage}
              />
              <Route
                path="/cart/payment/success" exact
                component={CartPaymentSuccessPage}
              />
              <Route
                path="/cart/request/success/:mobile" exact
                component={CartRequestSuccessPage}
              />
              <Route
                path="/cart/request/success" exact
                component={CartRequestSuccessPage}
              />
              <Route
                path="/cart/payment/processing" exact
                component={CartPaymentProcessing}
              />
              <Route
                path="/cart/request/processing" exact
                component={CartPaymentProcessingRe}
              />
              <Route path="/cart/payment" exact component={CartPaymentPage} />
              <Route path="/cart" exact component={CartPage} />

              <Route
                path={PRODUCT_LIST_PAGE_PATHS} exact
                component={ProductListPage}
              />

              <Route path="/product/:id" exact component={ProductPage} />

          <Route path="/notification/settings" exact component={NotificationSettingPage} />
          <Route path="/notification/key/:key" exact component={NotificationDetailPage} />
          <Route path="/notification/content/:contentId" exact component={NotificationDetailPage} />
          <Route path="/notification/:id" exact component={NotificationDetailPage} />
          <Route path="/notification" exact component={NotificationListPage} />

              <AuthRoute
                path="/signup/success"
                component={SignupSuccessPage}
                guestOnly
              />
              <AuthRoute path="/personal-verification" component={PersonalVerificationPage} guestOnly />
              <AuthRoute path="/login" component={LoginPage} guestOnly />
              <AuthRoute path="/find/id" component={FindId} guestOnly />
              <AuthRoute path="/find/password" component={FindPassword} guestOnly />
              <Route path="/my/liked" exact component={MyLikedPage} />
              <Route path="/mypage" render={() => <MyPage setIsNetFunnelPassed={setIsNetFunnelPassed} />} />
              <Route path="/corporate-responsibility" component={CorporateResponsibilityPage} />
              <Route path="/m/category" exact component={MobileCategoryPage} />
              <Route path="/" component={HomePage} exact />
              <Route path="/">
                <Redirect to="/" />
              </Route>
            </Switch>
          </main>
          <Switch>
            <Route
              path={[
                '/signup',
                '/personal-verification',
                '/login',
                '/non-member',
                '/app-download',
                '/find/id',
                '/find/password',
              ]}
            />
            <Route path={'/'} component={Footer} />
          </Switch>

          <BottomNavBar />
          <div className="md:hidden" style={{ height: 80 }} />
        </div>) : <></>}
    </>
  );
};
