import {NotiMessageDto} from "../../types/notification";
import dayjs from "dayjs";
import Logo from '../../assets/img/logo_carhartt.png';
import { isDatePassed } from '../../plugins/timeUtils';

type NotificationListItemProps = {
    notification: NotiMessageDto;
    onclick: VoidFunction
}

const NotificationListItem = ({notification, onclick}: NotificationListItemProps) => {
    const {description, displayStartDate, imageUrl, title, pinnedStartDate, readTs} = notification;

    const isPinnedItem = pinnedStartDate && !isDatePassed(pinnedStartDate);
    const isContentViewed = (!pinnedStartDate && Boolean(readTs));

    const contentViewedStyle = isContentViewed && 'opacity-50 text-gray-500';


    return (
        <div className={`py-5 px-4 bg-white ${isPinnedItem && 'border-t-2 border-black'} ${contentViewedStyle}`} onClick={onclick}>
            <div className={`flex space-x-4`}>
                <div>
                    <img src={imageUrl || Logo} alt={'notice_icon'} className={'w-11 h-11'}/>
                </div>
                <div className={'flex-1 space-y-1'}>
                    <div className={'text-12 font-bold'}>{title}</div>
                    <div className={'text-12 font-normal'}>{description}</div>
                    <div className={'text-12 text-gray-500'}>
                        {dayjs(displayStartDate).format('YYYY-MM-DD')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationListItem;
